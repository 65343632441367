import MediaDevice from './MediaDevice';
import Emitter from './Emitter';


const PC_CONFIG = { iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }] };

export class PeerShareConnection extends Emitter {


  constructor(meetingId) {
    super();
    this.meetingId = meetingId
    this.userId = JSON.parse(localStorage.getItem("user"))?.id 
    this.full_name = JSON.parse(localStorage.getItem("user"))?.full_name
    this.pc = null
    this.mediaDevice = new MediaDevice();
    this.stream = null
  }

  /**
   * Starting the call
   * @param {Boolean} isCaller
   */
  start() {
    this.mediaDevice
      .on('stream', (stream) => {
        this.stream = stream
        this.emit('localStream', stream)
        this.emit('mediaDevice',this.mediaDevice) 
      })
      .start();

    return this;
  }


  /**
   * Stop the call
   * @param {Boolean} isStarter
   */
  stop() {
    this.mediaDevice.stop();
    this.pc.destroy();
    this.pc = null;
    this.off();
    return this;
  }
}
