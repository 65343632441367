import React, {useRef, useEffect } from 'react'

const PeerVideo = ({ peer,peerKey }) => {
    const ref = useRef();
    useEffect(() => {
        if (peer.streams && peer.streams.length > 0) {
            ref.current.srcObject = peer.streams[0];
        }
        peer.on('stream', (stream) => {
            ref.current.srcObject = stream;
        });

        // return () => {
        //   peer.removeListener('stream');
        // };
    }, [peer,peerKey]);
    return <video ref={ref} autoPlay playsInline muted/>;
  };

export default PeerVideo