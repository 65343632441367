import React from "react";
import "../../assets/css/FullScreenDialog.css";
import {
  Avatar,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  styled,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useDateTime } from "hooks/useDateTime";
import {
  Mic,
  Videocam,
  EmojiEmotions,
  ScreenShare,
  EmojiPeople,
  PhoneDisabled,
  People,
  Message,
  InfoRounded,
  MicOff,
  VideocamOff,
} from "@material-ui/icons";
import PreMeetingScreen from "./PreMeetingScreen";
import { useLocation, useHistory } from "react-router-dom";
import { useWebrtc } from "hooks/useWebrtc";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#333",
    color: "#fff",
  },
  box: {
    maxHeight: "100%",
    maxWidth: "100%",
    flex: 1,
    display: "flex",
    gap: theme.spacing(1),
  },
  userContainer: {
    flex: 1,
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  videoContainer: {
    backgroundColor: theme.palette.grey[800],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  videoMessage: {
    color: theme.palette.grey[400],
    fontSize: "1.2rem",
  },
  videoAvatar: {
    width: 200,
    height: 200,
    fontSize: "4rem",
    backgroundColor: "#8b4c70",
  },
  controlContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    paddingRight: "80px",
  },
  endCall: {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  backgroundColor: "#444",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  boxShadow: theme.shadows[3],
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "none",
  borderRadius: theme.shape.borderRadius,
  color: "#fff",
  padding: theme.spacing(1),
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const TeacherMeet = () => {
  const classes = useStyles();
  const {
    localVideoRef,
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    joinCall,
    leaveCall,
  } = useWebrtc();
  const [controls, setControls] = React.useState(() => ["mic", "cam"]);
  const { time } = useDateTime();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const isReady = queryParams.get("ready");
  const isJoin = queryParams.get("join");

  const userDetail = JSON.parse(localStorage.getItem("user"));

  const handleChange = (event, newControls) => {
    setControls(newControls);
  };

  const handleEndCall = () => {
    leaveCall();
    history.push("/admin/TeacherSupport");
  };

  if (isReady) {
    return (
      <PreMeetingScreen
        ref={localVideoRef}
        {...{ isAudioMuted, toggleAudio, toggleVideo, isVideoMuted, joinCall }}
      />
    );
  } else if (!isJoin) {
    return null;
  }

  return (
    <Container maxWidth={"xl"} className={classes.container}>
      <Box className={classes.box}>
        {/* user container */}
        <Box className={classes.userContainer}>
          <Paper elevation={2} className={classes.videoContainer}>
            {isVideoMuted ? (
              <Typography className={classes.videoMessage}>
                <Avatar color="primary" className={classes.videoAvatar}>
                  {userDetail.full_name
                    .split(" ")
                    .map((name) => name[0])
                    .join("")}
                </Avatar>
              </Typography>
            ) : (
              <video
                muted
                ref={localVideoRef}
                className={classes.video}
                autoPlay
              />
            )}
          </Paper>
        </Box>
      </Box>

      {/* call controls */}
      <Box className={classes.controlContainer}>
        <div>
          <span>{time}</span>
          <span> | teacher-support</span>
        </div>
        <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <StyledToggleButtonGroup
            color="primary"
            value={controls}
            onChange={handleChange}
          >
            <StyledToggleButton onClick={toggleAudio} value="mic">
              {isAudioMuted ? <MicOff /> : <Mic />}
            </StyledToggleButton>
            <StyledToggleButton onClick={toggleVideo} value="cam">
              {isVideoMuted ? <VideocamOff /> : <Videocam />}
            </StyledToggleButton>
            <StyledToggleButton value="emoji">
              <EmojiEmotions />
            </StyledToggleButton>
            <StyledToggleButton value="share-screen">
              <ScreenShare />
            </StyledToggleButton>
            <StyledToggleButton value="raise-hand">
              <EmojiPeople />
            </StyledToggleButton>
          </StyledToggleButtonGroup>
          <Button className={classes.endCall} onClick={handleEndCall}>
            <PhoneDisabled />
          </Button>
        </Box>

        <Box style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <StyledToggleButton value="info">
            <InfoRounded />
          </StyledToggleButton>

          <StyledToggleButton value="people">
            <People />
          </StyledToggleButton>

          <StyledToggleButton value="message">
            <Message />
          </StyledToggleButton>
        </Box>
      </Box>
    </Container>
  );
};

export default TeacherMeet;
