import React from "react";
import SimplePeer from "simple-peer";
import { io } from "socket.io-client";
import { useMediaStream } from "./useMediaStream";

const socket = io("http://localhost:8000/meeting-id", { autoConnect: false });

export const useWebrtc = () => {
  const {
    ref: localVideoRef,
    stream: localStream,
    play,
    isPlaying,
    toggleAudio,
    toggleVideo,
    isVideoMuted,
    isAudioMuted,
    stop,
  } = useMediaStream({
    audioDeviceId: undefined,
    videoDeviceId: undefined,
  });
  const [peers, setPeers] = React.useState(new Map());

  React.useEffect(() => {
    play();
  }, [play]);

  React.useEffect(() => {
    socket.on("connected peers", handleConnectedPeers);
    socket.on("connected peer", handleNewPeer);
    socket.on("disconnected peer", handlePeerDisconnect);
    socket.on("signal", handleSignal);

    return () => {
      socket.off("connected peers", handleConnectedPeers);
      socket.off("connected peer", handleNewPeer);
      socket.off("disconnected peer", handlePeerDisconnect);
      socket.off("signal", handleSignal);
    };
  }, [peers]);

  const createPeer = (peerId, initiator) => {
    const peer = new SimplePeer({
      initiator,
      stream: localStream.current || undefined,
      trickle: false,
    });

    peer.on("signal", (data) => {
      socket.emit("signal", {
        recipient: peerId,
        sender: socket.id,
        signal: data,
      });
    });

    peer.on("stream", (remoteStream) => {
      setPeers((prev) => {
        const updatedPeers = new Map(prev);
        updatedPeers.set(peerId, { id: peerId, peer, stream: remoteStream });
        return updatedPeers;
      });
    });

    peer.on("close", () => {
      setPeers((prev) => {
        const updatedPeers = new Map(prev);
        updatedPeers.delete(peerId);
        return updatedPeers;
      });
      peer.destroy();
    });

    setPeers((prev) =>
      new Map(prev).set(peerId, { id: peerId, peer, stream: null })
    );
  };

  const handleConnectedPeers = (peerIds) => {
    peerIds.forEach((peerId) => {
      if (peerId !== socket.id) createPeer(peerId, true);
    });
  };

  const handleNewPeer = (peerId) => {
    createPeer(peerId, false);
  };

  const handlePeerDisconnect = (peerId) => {
    const peerData = peers.get(peerId);
    if (peerData) {
      peerData.peer.destroy();
      setPeers((prev) => {
        const updatedPeers = new Map(prev);
        updatedPeers.delete(peerId);
        return updatedPeers;
      });
    }
  };

  const handleSignal = ({ sender, signal }) => {
    const peerData = peers.get(sender);
    if (peerData) {
      peerData.peer.signal(signal);
    }
  };

  const joinCall = () => {
    if (!isPlaying) play();
    socket.connect();
  };

  const leaveCall = () => {
    socket.disconnect();
    peers.forEach((peerData) => peerData.peer.destroy());
    setPeers(new Map());
    stop();
  };

  return {
    joinCall,
    leaveCall,
    localVideoRef,
    peers,
    toggleAudio,
    toggleVideo,
    isVideoMuted,
    isAudioMuted,
  };
};
